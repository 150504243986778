import React, { useEffect } from "react";
import { useCart } from "../../utilities/CartContext";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export default function Cart({ setOpen }) {
  const { state, dispatch } = useCart();
  const total = state.items.reduce(
    (acc, item) => acc + Number(item.price) * item.quantity,
    0
  );

  const handleRemove = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: { id } });
  };

  // function that checks if url has /checkout
  const checkUrl = () => {
    const url = window.location.pathname;
    if (url.includes("/checkout")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div style={{ width: 350, padding: "20px" }}>
        <Typography variant="h6" component="div">
          Shopping Cart
        </Typography>
        <List dense>
          {state.items.length > 0 ? (
            state.items.map((item) => (
              <React.Fragment key={item.id}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={item.url} alt={item.productName} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.productName}
                    secondary={`$${Number(item.price).toFixed(2)} x ${
                      item.quantity
                    }`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleRemove(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No items in the cart" />
            </ListItem>
          )}
        </List>
        {state.items.length > 0 && (
          <>
            <Typography
              variant="h6"
              component="div"
              style={{ marginTop: "20px" }}
            >
              Total: ${total.toFixed(2)}
            </Typography>
            {!checkUrl() && (
              <Link to="/checkout">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: "20px" }}
                >
                  Checkout
                </Button>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
}
