import { db } from "../utilities/FireStore.js";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

export async function getStore() {
  const store = collection(db, "store");
  const storeSnapshot = await getDocs(store);
  const storeList = storeSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(), // Spread the document data into the new object
  }));
  return storeList;
}

// Function to delete a document from 'store' collection by ID
export async function deleteStoreDocument(docId) {
  try {
    const docRef = doc(db, "store", docId);
    await deleteDoc(docRef);
    console.log(`Document with ID ${docId} has been successfully deleted.`);
  } catch (error) {
    console.error("Error deleting document: ", error);
  }
}

// function to add a store document
export async function addStoreDocument(data) {
  try {
    const storeCollection = collection(db, "store");
    const docRef = await addDoc(storeCollection, data);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
}

// Function to get a single document from 'store' collection by ID
export async function getStoreDocument(docId) {
  try {
    const docRef = doc(db, "store", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document: ", error);
  }
}

// Function to update a document in 'store' collection by ID
export async function updateStoreDocument(docId, updatedData) {
  try {
    const docRef = doc(db, "store", docId);
    await updateDoc(docRef, updatedData);
    console.log(`Document with ID ${docId} has been successfully updated.`);
  } catch (error) {
    console.error("Error updating document: ", error);
  }
}

// function to add a order document
export async function addOrderDocument(data) {
  try {
    const storeCollection = collection(db, "order");
    const docRef = await addDoc(storeCollection, data);
    console.log("Document written with ID: ", docRef.id);

    data.items.forEach(async (item) => {
      updateStoreDocument(item.id, { purchased: true });
    });

    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
  }
}

// Function to get a single document from 'order' collection by ID
export async function getOrderDocument(docId) {
  try {
    const docRef = doc(db, "order", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document: ", error);
  }
}
