import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackButton = ({ backUrl }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (backUrl) {
      navigate(backUrl);
      return;
    }
    navigate(-1);
  };

  return (
    <IconButton
      onClick={handleBackClick}
      style={{
        position: "fixed",
        top: "70px",
        left: "15px",
        zIndex: 1000,
      }}
      aria-label="back"
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
