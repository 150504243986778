import React, { useEffect } from "react";
import "@aileron/date-picker";
import { Link } from "react-router-dom";
import "../styles/homePage.scss";
import HomePageCard from "../components/HomePageCard";
import Typography from "@mui/material/Typography";
import PageWrapper from "../components/PageWrapper.js";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageWrapper hideBackButton>
      <div className="home-page-wrapper">
        {/* <h1>Welcome to the Homepage</h1>
      <p>This is a basic homepage component for your React app.</p> */}
        <div className="home-page-card-wrapper">
          <HomePageCard
            name="Noah"
            description="Click here to enter"
            link="noah-shop"
          />
          <HomePageCard
            name="Raylah"
            description="Click here to enter"
            link="raylah-shop"
          />
        </div>
      </div>
    </PageWrapper>
  );
}

export default HomePage;
