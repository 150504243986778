import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import PageWrapper from "../components/PageWrapper.js";
import { getOrderDocument, getStoreDocument } from "../api/store.js";

const OrderSummary = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStoreData(id);
  }, []);

  const getStoreData = async (id) => {
    try {
      const orderData = await getOrderDocument(id);
      setOrder(orderData);
      const productPromises = orderData.items.map((item) =>
        getStoreDocument(item.id)
      );
      const productData = await Promise.all(productPromises);
      setProducts(productData);
    } catch (error) {
      console.error("Error fetching store data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (!order) {
    return <Typography variant="h6">Order not found</Typography>;
  }

  return (
    <PageWrapper hideBackButton>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Order Summary
        </Typography>
        <Typography variant="h6">Billing Information</Typography>
        <Typography variant="body1">Full Name: {order.fullName}</Typography>
        <Typography variant="body1">Email: {order.email}</Typography>
        <Typography variant="body1">Address: {order.address}</Typography>
        <Typography variant="body1">City: {order.city}</Typography>
        <Typography variant="body1">State: {order.state}</Typography>
        <Typography variant="body1">Postal Code: {order.postalCode}</Typography>
        <Typography variant="body1">Country: {order.country}</Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Order Details
        </Typography>
        <Card variant="outlined" sx={{ mt: 2 }}>
          <CardContent>
            <List disablePadding>
              {products.map((item, index) => (
                <ListItem key={index} sx={{ py: 1, px: 0 }}>
                  <Avatar alt={item.productName} src={item.url} />
                  <ListItemText
                    primary={item.productName}
                    secondary={`created by: ${item.owner}`}
                  />
                  <Typography variant="body2">
                    ${Number(item.price).toFixed(2)}
                  </Typography>
                </ListItem>
              ))}
              <Divider />
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary="Total" />
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  $
                  {products &&
                    products.reduce((acc, item) => acc + Number(item.price), 0)}
                </Typography>
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Typography variant="h6" sx={{ mt: 4 }}>
          Order confirmation and payment options will be sent to your email.
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Or you can pay here with Venmo
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          href="https://venmo.com/shawn-tompke"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pay with Venmo
        </Button>
      </Box>
    </PageWrapper>
  );
};

export default OrderSummary;
