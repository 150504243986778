// src/context/CartContext.js
import React, { createContext, useReducer, useContext } from "react";

// Create the cart context
const CartContext = createContext();

// Action types for easier management of reducer cases
const actionTypes = {
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  UPDATE_ITEM: "UPDATE_ITEM",
  CLEAR_CART: "CLEAR_CART",
};

// Reducer to handle actions
function cartReducer(state, action) {
  switch (action.type) {
    case actionTypes.ADD_ITEM:
      return { ...state, items: [...state.items, action.payload] };
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload.id),
      };
    case actionTypes.UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      };
    case actionTypes.CLEAR_CART:
      return { ...state, items: [] };
    default:
      return state;
  }
}

// Provider component
const CartProvider = ({ children }) => {
  const initialState = { items: [] };
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook for easy access to the cart context
const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCart, actionTypes };
