import React, { useEffect } from "react";
import BackButton from "../BackButton";

export default function PageWrapper({ children, backUrl, hideBackButton }) {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div style={{ padding: 40 }}>
      {!hideBackButton && <BackButton backUrl={backUrl} />}
      {children}
    </div>
  );
}
