import React, { useState, useEffect } from "react";
import ShopCard from "../components/ShopCard";
import Masonry from "@mui/lab/Masonry";
import DrawerComponent from "../components/DrawerComponent";
import FileUpload from "../components/FileUpload";
import {
  getRaylahStore,
  deleteRaylahStoreDocument,
} from "../api/raylahStore.js";
import {
  getStore,
  deleteStoreDocument,
  updateStoreDocument,
} from "../api/store.js";
import { useAuth } from "../utilities/AuthProvider.js";
import {
  getNoahStore,
  deleteNoahStoreDocument,
  updateNoahStoreDocument,
} from "../api/noahStore.js";
import Typography from "@mui/material/Typography";
import { useCart } from "../utilities/CartContext";
import PageWrapper from "../components/PageWrapper.js/index.js";

export default function ShopPage({ folder, owner }) {
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  const { state, dispatch } = useCart();

  const addToCart = (product) => {
    dispatch({
      type: "ADD_ITEM",
      payload: {
        ...product,
        quantity: 1, // default quantity can be 1 or another logic
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [storeList, setStoreList] = useState([]);

  const fetchProducts = () => {
    getStore().then((storeListData) => {
      console.log("storeListData: ", storeListData);
      console.log("owner: ", owner);
      const filteredList = storeListData.filter((item) => item.owner === owner);
      setStoreList(filteredList);
    });

    // if (owner === "raylah") {
    //   getRaylahStore().then((storeListData) => {
    //     setStoreList(storeListData);
    //   });
    // } else if (owner === "noah") {
    //   getNoahStore().then((storeListData) => {
    //     setStoreList(storeListData);
    //   });
    // } else {
    //   console.log("owner not found");
    // }
  };

  const deleteProductHandler = (id) => {
    return deleteStoreDocument(id);
    // if (owner === "raylah") {
    //   return deleteRaylahStoreDocument(id);
    // } else if (owner === "noah") {
    //   return deleteNoahStoreDocument(id);
    // } else {
    //   console.log("owner not found");
    // }
  };

  const updateProductHandler = (id, updatedData) => {
    return updateStoreDocument(id, updatedData);
    // if (owner === "ralyah") {
    //   // return updateRaylahStoreDocument(id, updatedData);
    // } else if (owner === "noah") {
    //   return updateNoahStoreDocument(id, updatedData);
    // }
  };

  useEffect(() => {
    fetchProducts();
  }, [open]);

  return (
    <PageWrapper>
      <Typography variant="h2" gutterBottom>
        {owner}'s Shop Page
      </Typography>
      {currentUser === null ? null : (
        <DrawerComponent title="Add New Product" setOpen={setOpen} open={open}>
          <FileUpload setOpen={setOpen} owner={owner} />
        </DrawerComponent>
      )}
      <button onClick={() => setOpen(!open)}></button>
      <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
        {
          // sort storeList by item.createdAt

          storeList
            .sort((a, b) => {
              return b.createdAt - a.createdAt;
            })
            .map((item) => {
              if (item.purchased === true && !currentUser) return null;

              return (
                <div key={item.id}>
                  <ShopCard
                    purchased={item.purchased}
                    cartState={state}
                    fetchProducts={fetchProducts}
                    deleteProduct={deleteProductHandler}
                    name={item.productName}
                    price={item.price}
                    url={item.url}
                    id={item.id}
                    description={item.description}
                    folder={folder}
                    owner={folder}
                    onAddToCart={() => addToCart(item)}
                  />
                </div>
              );
            })
        }
      </Masonry>
    </PageWrapper>
  );
}
