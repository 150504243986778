// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpyfiTPQypCnJ1oyb9K_e0GI_n5bkSDp4",
  authDomain: "jamesriver-4ba7f.firebaseapp.com",
  projectId: "jamesriver-4ba7f",
  storageBucket: "jamesriver-4ba7f.appspot.com",
  messagingSenderId: "647522445212",
  appId: "1:647522445212:web:e437fa0f541767239d0a59",
  measurementId: "G-47T4W9H5G7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, storage, auth };
