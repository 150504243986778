// src/components/FileUpload.js
import React, { useState } from "react";
import { storage, db } from "../../utilities/FireStore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const FileUpload = ({ owner, setOpen }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  // add price state
  const [price, setPrice] = useState(0);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.error("Upload error: ", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // Ensure db is correctly passed here
          const imagesCollection = collection(db, "store"); //ex: raylah-shop
          addDoc(imagesCollection, {
            url: downloadURL,
            fileName: file.name,
            createdAt: new Date(),
            productName: productName ? productName : "No name",
            price: price ? price : "No price",
            purchased: false,
            description: description,
            owner: owner,
          })
            .then(() => {
              setFile(null);
              setProgress(0);
              setOpen(false);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        });
      }
    );
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="product-name"
        label="Product Name"
        variant="outlined"
        onChange={(e) => setProductName(e.target.value)}
      />
      <TextField
        id="price"
        label="Price"
        variant="filled"
        onChange={(e) => setPrice(e.target.value)}
      />
      <TextField
        id="description"
        label="Description"
        variant="filled"
        multiline
        onChange={(e) => setDescription(e.target.value)}
      />
      <input type="file" onChange={handleChange} />

      <Button onClick={handleUpload} id="upload-button" variant="contained">
        Upload
      </Button>
      <div>Progress: {progress}%</div>
    </Box>
  );
};

export default FileUpload;
