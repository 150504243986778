import React from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getNoahStoreDocument } from "../../api/noahStore";
import { getRaylahStoreDocument } from "../../api/raylahStore";
import { getStoreDocument } from "../../api/store.js";
import { useAuth } from "../../utilities/AuthProvider";
import { useCart } from "../../utilities/CartContext";
import PageWrapper from "../PageWrapper.js";

const ProductPage = () => {
  const { id, store } = useParams();
  const [product, setProduct] = React.useState({});
  const { currentUser } = useAuth();
  const { state, dispatch } = useCart();

  const productIsInCart = (id) => {
    return state.items.some((item) => item.id === id);
  };

  const addToCart = (product) => {
    dispatch({
      type: "ADD_ITEM",
      payload: {
        ...product,
        // id: product.id,
        // name: product.productName,
        // price: product.price,
        // url: product.url,
        quantity: 1, // default quantity can be 1 or another logic
      },
    });
  };

  const getStoreData = (id) => {
    return getStoreDocument(id).then((product) => {
      setProduct(product);
    });

    // if (store === "raylah-shop") {
    //   return getRaylahStoreDocument(id).then((product) => {
    //     setProduct(product);
    //   });
    // } else if (store === "noah-shop") {
    //   return getNoahStoreDocument(id).then((product) => {
    //     setProduct(product);
    //   });
    // } else {
    //   console.log("store not found");
    // }
  };

  React.useEffect(() => {
    getStoreData(id);
    scrollToTop();
  }, [id]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <PageWrapper>
      <Container>
        <Box my={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="300"
                  image={product.url}
                  alt={product.name}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h4" component="h1" gutterBottom>
                    {product.productName}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    {product.description
                      ? product.description
                      : "No description"}
                  </Typography>
                  <Typography variant="h5" component="p" gutterBottom>
                    ${Number(product.price).toFixed(2)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.purchased ? (
                      <Typography gutterBottom variant="h4" component="div">
                        PURCHASED
                      </Typography>
                    ) : currentUser === null ? (
                      <Button
                        disabled={productIsInCart(product.id)}
                        onClick={() => addToCart(product)}
                        variant="contained"
                        color="primary"
                      >
                        {productIsInCart(product.id)
                          ? "in cart"
                          : "Add to Cart"}
                      </Button>
                    ) : null}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default ProductPage;
