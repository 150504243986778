import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useCart } from "../utilities/CartContext";
import PageWrapper from "../components/PageWrapper.js";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { addOrderDocument } from "../api/store.js";
import { useNavigate } from "react-router-dom";

const CheckoutPage = ({ setOpen }) => {
  const { state, dispatch } = useCart();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  console.log(state);
  const total = state.items.reduce(
    (acc, item) => acc + Number(item.price) * item.quantity,
    0
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const orderData = {
      fullName: formData.get("fullName"),
      email: formData.get("email"),
      address: formData.get("address"),
      city: formData.get("city"),
      state: formData.get("state"),
      postalCode: formData.get("postalCode"),
      country: formData.get("country"),
      items: state.items,
      total: total.toFixed(2),
    };

    console.log('formData.get("fullName"): ', formData.get("fullName"));

    console.log("orderData: ", orderData);

    // Here you would handle the form submission to your backend
    console.log("Processing checkout");

    const itemIds = state.items.map((item) => item.id);
    console.log("itemIds: ", itemIds);
    addOrderDocument(orderData).then(async (id) => {
      dispatch({ type: "CLEAR_CART" });

      // Send confirmation email
      await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: orderData.email,
          orderDetails: orderData,
          orderId: id,
        }),
      });

      console.log("id", id);
      setOpenSnackbar(true);
      console.log("Order added to database");
      navigate(`/order-summary/${id}`);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(false);
  }, []);

  return (
    <PageWrapper>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Checkout
        </Typography>
        <Typography variant="h6">Billing Information</Typography>
        <TextField
          required
          label="Full Name"
          name="fullName"
          fullWidth
          autoComplete="name"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          name="email"
          required
          label="Email Address"
          fullWidth
          autoComplete="email"
          type="email"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          name="address"
          required
          label="Address"
          fullWidth
          autoComplete="shipping address-line1"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          name="city"
          label="City"
          fullWidth
          autoComplete="shipping address-level2"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          name="state"
          label="State/Province/Region"
          fullWidth
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          name="postalCode"
          label="Zip / Postal code"
          fullWidth
          autoComplete="shipping postal-code"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          name="country"
          label="Country"
          fullWidth
          autoComplete="shipping country"
          variant="outlined"
          sx={{ mt: 2 }}
        />

        <Typography variant="h6" sx={{ mt: 4 }}>
          Order Summary
        </Typography>
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          color="secondary"
          sx={{ mt: 2 }}
        >
          Edit Cart
        </Button>
        <Card variant="outlined" sx={{ mt: 2 }}>
          <CardContent>
            <List disablePadding>
              {state.items.map((item) => (
                <ListItem key={item.id} sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    primary={item.productName}
                    secondary={`created by: ${item.owner}`}
                  />
                  <Typography variant="body2">
                    ${Number(item.price).toFixed(2)}
                  </Typography>
                </ListItem>
              ))}
              <Divider />
              <ListItem sx={{ py: 1, px: 0 }}>
                <ListItemText primary="Total" />
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  ${total.toFixed(2)}
                </Typography>
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
        >
          Place Order
        </Button>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(true)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Checkout Successful! Check your email for confirmation.
        </Alert>
      </Snackbar>
    </PageWrapper>
  );
};

export default CheckoutPage;
