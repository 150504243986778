import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

export default function DrawerComponent({
  children,
  open,
  setOpen,
  title,
  variant,
  hideButton = false,
  disabled,
}) {
  return (
    <div>
      {!hideButton && (
        <Button
          disabled={disabled}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          {title ? title : "Open"}
        </Button>
      )}
      <Drawer
        onClose={() => setOpen(false)}
        variant={variant}
        anchor="right"
        open={open}
      >
        {children}
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Drawer>
    </div>
  );
}
