import React from "react";
import "./index.scss"; // Assuming you will use a separate CSS file
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

const colors = {
  warmBrown: "#A0522D",
  sandyYellow: "#D2B48C",
  mutedOrange: "#DEB887",
  softCream: "#F5DEB3",
  desertRed: "#CD5C5C",
  deepOlive: "#556B2F",
};

const HomePageCard = ({ name, description, link }) => {
  return (
    <div className="card-container">
      <div className="card" style={{ backgroundColor: colors.softCream }}>
        <Typography variant="h2" noWrap>
          {name}
        </Typography>
        <Typography
          variant="p"
          href="#app-bar-with-responsive-menu"
          sx={{
            padding: 4,
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          {description}
        </Typography>
        <Link to={link}>
          <button
            style={{
              backgroundColor: colors.desertRed,
              color: colors.softCream,
            }}
          >
            Enter
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HomePageCard;
