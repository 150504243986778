import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DrawerComponent from "../DrawerComponent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import { useAuth } from "../../utilities/AuthProvider.js";

import EditProduct from "../EditProduct/index.js";

export default function ShopCard({
  name,
  url,
  price,
  id,
  deleteProduct,
  fetchProducts,
  description,
  folder,
  onAddToCart,
  cartState,
  purchased,
}) {
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const { currentUser } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    await deleteProduct(id);
    handleClose();
    fetchProducts();
  };

  const productIsInCart = (id) => {
    return cartState.items.some((item) => item.id === id);
  };

  return (
    <Card sx={{ maxWidth: 345, margin: 5 }}>
      <CardMedia sx={{ height: 300 }} image={url} title={name} />
      <CardContent>
        {purchased && (
          <Typography
            style={{ color: "red" }}
            gutterBottom
            variant="h4"
            component="div"
          >
            PURCHASED
          </Typography>
        )}
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ${Number(price).toFixed(2)}
        </Typography>
      </CardContent>
      <CardActions style={{ flexWrap: "wrap" }}>
        {currentUser === null ? (
          <Button
            disabled={productIsInCart(id) || purchased}
            onClick={() => onAddToCart({ id, name, price, description, url })}
            size="small"
          >
            {productIsInCart(id) ? "in Cart" : "Add to Cart"}
          </Button>
        ) : null}
        <Link to={`/product/${folder}/${id}`}>
          <Button size="small">View</Button>
        </Link>

        {currentUser === null ? null : (
          <DrawerComponent
            title="Edit Product"
            setOpen={setEditOpen}
            open={editOpen}
            disabled={purchased}
          >
            <EditProduct
              id={id}
              setOpen={setEditOpen}
              fetchProducts={fetchProducts}
              description={description}
              folder={folder}
              price={price}
              name={name}
              url={url}
            />
          </DrawerComponent>
        )}

        <React.Fragment>
          {currentUser === null ? null : (
            <Button color="error" size="small" onClick={handleClickOpen}>
              delete 🗑️
            </Button>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to delete: ${name}?`}
            </DialogTitle>
            <DialogContent>You cannot undo this action.</DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button color="error" onClick={() => handleDelete(id)} autoFocus>
                Yes, Delete {name}!
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </CardActions>
    </Card>
  );
}
