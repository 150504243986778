import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useCart } from "./utilities/CartContext";
import Navigation from "./components/Navigation";
import Cart from "./components/Cart";
import HomePage from "./pages/HomePage";
import ShopPage from "./pages/ShopPage";
import ProductPage from "./components/ProductPage";
import DrawerComponent from "./components/DrawerComponent";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import OrderSummary from "./pages/OrderSummary";
import Checkout from "./pages/CheckoutPage";
import { getCities } from "./api/cities";
import "./App.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function App() {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useCart();

  useEffect(() => {
    getCities().then((cityList) => {
      // Optional: Handle city list if needed
    });
  }, []);

  useEffect(() => {
    if (state.items.length > 0) {
      setOpen(true);
    }
  }, [state.items.length, setOpen]);

  return (
    <Router>
      <div className="App">
        <Navigation setOpenCart={setOpen} />
        <DrawerComponent hideButton setOpen={setOpen} open={open}>
          <Cart setOpen={setOpen} />
        </DrawerComponent>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/checkout" element={<Checkout setOpen={setOpen} />} />
          <Route
            path="/noah-shop"
            element={<ShopPage owner="noah" folder="noah-shop" />}
          />
          <Route
            path="/raylah-shop"
            element={<ShopPage owner="raylah" folder="raylah-shop" />}
          />
          <Route path="/product/:store/:id" element={<ProductPage />} />
          <Route path="/order-summary/:id" element={<OrderSummary />} />
          {/* Additional routes can be added here */}
          <Route path="*" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
