// src/components/FileUpload.js
import React, { useState, useEffect } from "react";
import { storage, db } from "../../utilities/FireStore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { updateNoahStoreDocument } from "../../api/noahStore.js";
import { updateRaylahStoreDocument } from "../../api/raylahStore.js";
import { updateStoreDocument } from "../../api/store.js";
import CardMedia from "@mui/material/CardMedia";

const EditProduct = ({
  id,
  description,
  url,
  name,
  price,
  folder,
  setOpen,
  fetchProducts,
}) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [productName, setProductName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newName, setNewName] = useState("");
  // add price state

  useEffect(() => {
    setNewDescription(description);
    setNewUrl(url);
    setNewPrice(price);
    setNewName(name);
  }, []);

  const handleChangeUrl = async (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  const handleUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.error("Upload error: ", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setNewUrl(downloadURL);
          setFile(null);
          setProgress(0);

          // Ensure db is correctly passed here
          // const imagesCollection = collection(db, folder); //ex: raylah-shop
          // addDoc(imagesCollection, {
          //   url: downloadURL,
          //   fileName: file.name,
          //   createdAt: new Date(),
          //   productName: productName ? productName : "No name",
          //   price: price ? price : "No price",
          //   purchased: false,
          //   description: description,
          // })
          //   .then(() => {
          //     setNewUrl(downloadURL);
          //     setFile(null);
          //     setProgress(0);
          //     // setOpen(false);
          //   })
          //   .catch((error) => {
          //     console.error("Error adding document: ", error);
          //   });
        });
      }
    );
  };

  const handleEdit = () => {
    const changes = {
      url: newUrl,
      price: newPrice,
      productName: newName,
      description: newDescription,
    };

    updateStoreDocument(id, changes).then(() => {
      setOpen(false);
      fetchProducts();
    });

    // if (folder === "raylah-shop") {
    //   updateRaylahStoreDocument(id, changes).then(() => {
    //     setOpen(false);
    //     fetchProducts();
    //   });
    // } else if (folder === "noah-shop") {
    //   updateNoahStoreDocument(id, changes).then(() => {
    //     setOpen(false);
    //     fetchProducts();
    //   });
    // } else {
    //   console.log("folder not found");
    // }
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25ch" },
        display: "flex",
        flexDirection: "column",
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        value={newName}
        id="product-name"
        label="Product Name"
        variant="outlined"
        onChange={(e) => setNewName(e.target.value)}
      />
      <TextField
        value={newPrice}
        id="price"
        label="Price"
        variant="filled"
        onChange={(e) => setNewPrice(e.target.value)}
      />
      <TextField
        value={newDescription}
        id="description"
        label="Description"
        variant="filled"
        multiline
        onChange={(e) => setNewDescription(e.target.value)}
      />
      {newUrl && <CardMedia sx={{ height: 300 }} image={newUrl} title={name} />}
      <input type="file" onChange={handleChangeUrl} />
      {progress > 0 && <div>Progress: {progress}%</div>}

      <Button onClick={handleEdit} id="upload-button" variant="contained">
        Confirm Changes
      </Button>
    </Box>
  );
};

export default EditProduct;
