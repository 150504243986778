import { db } from "../utilities/FireStore.js";
import { collection, getDocs } from "firebase/firestore";

export async function getCities() {
  const citiesCol = collection(db, "cities");
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(), // Spread the document data into the new object
  }));
  return cityList;
}
